import React from 'react'
import PrimaryLayout from '../components/layouts/PrimaryLayout'

const NotFoundPage = () => (
  <PrimaryLayout>
    <section className="page-404" style={{ height: '100vh' }}>
      <div className="elements" />

      <div className="container">
        <div className="text-404">
          <span>Ooops!</span>
          <h3>Oopss! Page not found on server</h3>
          <p>
            we can't seem to find a page you are looking for! Don't worry and
            try it out again!
          </p>

          <div className="button">
            <a className="btn-main" href="index.html">
              Go Home
            </a>
          </div>
        </div>
      </div>
    </section>
  </PrimaryLayout>
)

export default NotFoundPage
